@import "../mixins/media";

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

ex [商品詳細ページ](http://demo3.ec-cube.net/products/detail/18)


Markup:
include /assets/tmpl/elements/14.1.product.pug
+ec-productSimpleRole

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  & &__img {
    margin-right: 0;
    margin-bottom: 20px;
    @include media_desktop {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
  & &__profile {
    margin-left: 0;
    margin-bottom: 30px;
    @include media_desktop {
      margin-left: 16px;
    }
    
  }
  & &__title {
    .ec-headingTitle {
      font-size: 20px;
      font-weight: bold;
      color: #451f06;
      @include media_desktop {
        font-size: 26px;
      }
    }
  }
  & &__tags {
    margin-top: 16px;
    padding: 15px 0 0;
    border-top: 1px solid #451f06;
   }
  & &__tag {
    display: inline-block;
    padding: 2px 5px;
    list-style: none;
    font-size: 80%;
    color: #525263;
    /*border: solid 1px #D7DADD;*/
    border-radius: 3px;
    background-color: #F5F7F8;
    color: #fff;
  }
  & &__priceRegular {
    padding-top: 14px
  }
  & &__priceRegularTax {
    margin-left: 5px;
    font-size: 10px;
  }
  & &__price {
    color: #DE5D50;
    font-size: 28px;
    padding: 0;
    border-bottom: 0;
    @include media_desktop {
      padding: 14px 0;
      /* border-bottom: 1px dotted #ccc; */
    }
  }
  & &__code {
    padding: 14px 0;
    /* border-bottom: 1px dotted #ccc; */
  }
  & &__category {
    padding: 14px 0;
    /* border-bottom: 1px dotted #ccc; */
    a {
      color: #33A8D0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  & &__actions {
    padding: 14px 0;
    .ec-select {
      select {
        height: 40px;
        max-width: 100%;
        min-width: 100%;
        @include media_desktop {
          min-width: 350px;
          max-width: 350px;
        }
      }
    }
  }
  & &__btn {
    width: 100%;
    margin-bottom: 10px;
    @include media_desktop {
      width: 60%;
      margin-bottom: 16px;
      min-width: 350px;
    }
  }
  & &__description {
    margin-bottom: 16px;
    zoom: 1;
    line-height: 2;
    overflow: hidden;
  }
  & &__description img {
    height: auto;
    @include media_desktop {
      width: auto;
    }
  }
  & &__description h3 {
    color: #451f06;
    padding-bottom: 10px;
    margin-bottom: 3px;
    line-height: 1.6;
    clear: both;
    font-size: 18px;
    @include media_desktop {
      font-size: 24px;
    }
  }
  & &__description h4 {
    @include media_desktop {
      float: left;
      margin-right: 20px;
    }
    margin-bottom: 10px;
  }
  & &__description h5 {
    @include media_desktop {
      float: right;
      margin-left: 20px;
    }
    margin-bottom: 10px;
  }
  & &__description .comment1 {
    margin-bottom: 20px;
  }
  & &__description #under_goodsimg {

  }
  & &__description #under_goodsimg tr td {
    vertical-align: top;
    padding: 10px 0;
    border-bottom: 1px dotted #afafaf;
    border-top: 1px dotted #afafaf;
  }
  & &__description #under_goodsimg tr td:first-child {
    white-space: nowrap;
  }

  & &__description #under_goodsimg tr td:first-child:after {
    content: " :";
  }

}