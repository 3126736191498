/*
見出し

トップページで使用されている見出しのスタイルです。

ex [トップページ](http://demo3.ec-cube.net/)

Markup:
sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 12.4
*/

/*
横並び見出し

横並びの見出しです。

Markup:
.ec-secHeading
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.1
*/
/*
.ec-secHeading {
  margin-bottom: 15px;
  color: black;
  & &__en{
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .2em;
  }
  & &__line{
    display: inline-block;
    margin: 0 20px;
    width: 1px;
    height: 14px;
    background: black;
  }
  & &__ja{
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .15em;
    vertical-align: 2px;
  }
}
*/

.ec-secHeading {
  text-align: center;
}
.ec-secHeading .ec-secHeading__dot{
  margin: 0 auto 20px;
  /*border-bottom: 1px dotted #ccc;*/
  /*border-top: 1px solid #ccc;*/
  padding: 0px 0px 10px;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  text-align: center;
  color: #451f06;
  display: inline-block;
  background: url("../img/common/h2-border.png") no-repeat center bottom;
  background-size: 100px auto;
  @include media_desktop {
    /*border-bottom: 1px solid #451f06;*/
    background-size: 200px auto;
    margin: 10px 20px 48px;
    padding: 5px 10px 10px;
    font-size: 22px;
    font-weight: bold;
  }
}
.ec-secHeading .ec-secHeading__dot:before{
  content: ' ';
  background: url("../img/common/h2-leaf.png");
  display: block;
  background-size: 20px 28px;
  width: 20px;
  height: 28px;
  position: absolute;
  top: 0;
  left: -50px;
  @include media_desktop {
    background-size: 44px 56px;
    width: 44px;
    height: 56px;
    left: -70px;
  }
}
.ec-secHeading .ec-secHeading__dot:after{
  content: ' ';
  background: url("../img/common/h2-leaf.png");
  display: block;
  background-size: 20px 28px;
  width: 20px;
  height: 28px;
  position: absolute;
  top: 0;
  right: -50px;
  @include media_desktop {
    background-size: 44px 56px;
    width: 44px;
    height: 56px;
    right: -70px;
  }
  transform: scale(-1, 1);
}



/*
縦並び見出し

縦並びの見出しです。

Markup:
.ec-secHeading--tandem
  span.ec-secHeading__en TOPIC
  span.ec-secHeading__line |
  span.ec-secHeading__ja 特集

Styleguide 12.4.2
*/

.ec-secHeading--tandem {
  margin-bottom: 15px;
  color: black;
  text-align: center;
  & .ec-secHeading__en{
    display: block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: .2em;
  }
  & .ec-secHeading__line{
    display: block;
    margin: 13px auto;
    width: 20px;
    height: 1px;
    background: black;
  }
  & .ec-secHeading__ja{
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .15em;
    vertical-align: 2px;
  }
}
