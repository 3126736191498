@import "../mixins/btn";
/*
ボタンサイズ

ボタンサイズを変更するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.1
*/

/*
通常ボタン

インラインの要素としてボタンを定義出来ます。

ex [トップページ　ボタン部分](http://demo3.ec-cube.net/)

Markup:
.ec-inlineBtn 住所検索
.ec-inlineBtn--primary もっと見る
.ec-inlineBtn--action カートに入れる
.ec-inlineBtn--cancel キャンセル

Styleguide 2.1.1
*/
.ec-inlineBtn{
  @include btn-default;
}
.ec-inlineBtn--primary{
  @include btn-primary
}
.ec-inlineBtn--action{
  @include btn-action
}
.ec-inlineBtn--cancel{
  @include btn-cancel
}

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

ex [商品詳細ページ　カートボタン部分](http://demo3.ec-cube.net/products/detail/30)

Markup:
p: .ec-blockBtn 住所検索
p: .ec-blockBtn--primary もっと見る
p: .ec-blockBtn--action カートに入れる
p: .ec-blockBtn--cancel キャンセル

Styleguide 2.1.2
*/
.ec-blockBtn--top {
  background-color: #451f06 !important;
}
.ec-blockBtn{
  @include blockBtn-default;
}
.ec-blockBtn--primary{
  @include blockBtn-primary
}
.ec-blockBtn--action{
  @include blockBtn-action
}
.ec-blockBtn--cancel{
  @include blockBtn-cancel
}

.add-cart:before {
  font-family: "Font Awesome 5 Free";
  content: "\f07a";
}